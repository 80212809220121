export const NAV_BANNER_AD_SIZES: ResponsiveAdSize[] = [
  {
    adDimensions: [[728, 90]],
    screenDimensions: [800, 0],
  },
  {
    adDimensions: [[320, 50]],
    screenDimensions: [0, 0],
  },
];
