import React from 'react';

import { NAV_BANNER_AD_SIZES } from 'constants/adSizes';

import ResponsiveNonProGoogleAd from 'components/GoogleAds/ResponsiveNonProGoogleAd';

import styles from './styles/GoogleAds.module.scss';

type Props = {
  hide?: boolean;
  adType: DyrtAdType;
  divId: string;
};

const GoogleAdNavBanner: React.FC<Props> = ({ hide, adType, divId }) => {
  return (
    <div className={styles['nav-banner-wrapper']}>
      <ResponsiveNonProGoogleAd
        sizes={NAV_BANNER_AD_SIZES}
        divId={divId}
        adType={adType}
        className={styles['nav-banner']}
        centered
        hide={hide}
      />
    </div>
  );
};

export default GoogleAdNavBanner;
