import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppIcon from 'components/AppIcon/AppIcon';

import _ArticleCard from './_ArticleCard';
import _CampgroundCard from './_CampgroundCard';
import _FilterCard from './_FilterCard';
import _CampgroundFullInfoCard from './_CampgroundFullInfoCard';

import styles from './styles/AppCardCarousel.module.scss';

const cx = classNames.bind(styles);
export interface AppCardCarouselProps {
  articles?: {
    id: number;
    title: string;
    author: string;
    date: string;
    link: string;
    picture: string;
    description: string;
    categories: string[];
  }[];
  filters?: SeoFilter[];
  place?: PlaceModel;
  campgrounds?: CampgroundOrLocationModel[];
  nearestCitySet?: PlaceModel[];
  className?: string;
  more?: string;
  hasBackground?: boolean;
  useFullInfoCampground?: boolean;
  hasButtons?: boolean | null;
}

export const AppCardCarousel: React.FC<AppCardCarouselProps> = ({
  articles,
  filters,
  place,
  campgrounds,
  nearestCitySet,
  className,
  more,
  hasBackground,
  useFullInfoCampground,
  hasButtons = true,
}: AppCardCarouselProps) => {
  const [currentCard, setCurrentCard] = useState(0);

  const cardContainerElement = useRef<HTMLDivElement>(null);

  const cardElement = useRef<Array<HTMLDivElement | HTMLAnchorElement>>([]);

  const cardCount =
    articles?.length || campgrounds?.length || filters?.length || 0;

  useEffect(() => {
    if (cardContainerElement.current) {
      cardContainerElement.current.scrollLeft =
        cardElement.current[currentCard]?.offsetLeft;
    }
  }, [currentCard]);

  const cardDirection = (direction: string) => {
    const cards = campgrounds || articles || filters || [];

    if (direction === 'next' && currentCard < cards.length) {
      checkPosition('next');
    }

    if (direction === 'prev' && currentCard > 0) {
      checkPosition('prev');
    }
  };

  const checkPosition = (direction: string) => {
    if (direction == 'next' && currentCard < cardElement.current.length) {
      if (
        cardContainerElement.current &&
        cardContainerElement.current.scrollLeft <
          cardElement.current[currentCard + 1]?.offsetLeft
      ) {
        setCurrentCard(currentCard + 1);
      } else {
        cardElement.current.forEach((card, index) => {
          if (
            card &&
            cardContainerElement.current &&
            cardContainerElement.current.scrollLeft > card.offsetLeft
          ) {
            setCurrentCard(index + 1);
          }
        });
      }
    }

    if (direction == 'prev' && currentCard > 0) {
      if (
        cardContainerElement.current &&
        cardContainerElement.current.scrollLeft >
          cardElement.current[currentCard - 1].offsetLeft
      ) {
        setCurrentCard(currentCard - 1);
      } else {
        for (let i = cardElement.current.length - 1; i >= 0; i--) {
          if (
            cardElement.current[i] &&
            cardContainerElement.current &&
            cardContainerElement.current.scrollLeft <
              cardElement.current[i].offsetLeft
          ) {
            setCurrentCard(i - 1);
          }
        }
      }
    }
  };

  const appCarouselClassNames = cx({
    'card-carousel': true,
    'card-carousel--is-campgrounds': campgrounds && !useFullInfoCampground,
    'card-carousel--is-campgroundsFullInfo':
      campgrounds && useFullInfoCampground,
    'card-carousel--is-articles': articles,
    'card-carousel--has-prev': currentCard > 0,
    'card-carousel--has-next': currentCard < cardCount,
  });

  return (
    <div className={`${appCarouselClassNames} ${className}`}>
      {hasButtons && (
        <>
          <AppIcon
            classNameWrapper={`${styles['direction']} ${styles['direction--is-prev']}`}
            classNameSvg={[styles['direction__icon']]}
            icon="chevron_left"
            onClick={() => cardDirection('prev')}
            dataEvent={`click_card_carousel_prev`}
          />
          <AppIcon
            classNameWrapper={`${styles['direction']} ${styles['direction--is-next']}`}
            classNameSvg={[styles['direction__icon']]}
            icon="chevron_right"
            onClick={() => cardDirection('next')}
            dataEvent={`click_card_carousel_next`}
          />
        </>
      )}
      <div
        className={styles['card-carousel__interior']}
        ref={cardContainerElement}
      >
        {campgrounds &&
          campgrounds?.length > 0 &&
          campgrounds.map((campground, index) => {
            const thisCampground = campground as CampgroundModel;
            let thisNearestCity;

            if (useFullInfoCampground && !!nearestCitySet) {
              thisNearestCity =
                nearestCitySet?.find(
                  (city) => city.id === thisCampground.nearestCityId
                ) || undefined;
            }

            return (
              <li key={campground.id} className={styles.listItem}>
                {useFullInfoCampground && !!nearestCitySet ? (
                  <_CampgroundFullInfoCard
                    className={styles.cardFullInfo}
                    hasBackground={hasBackground}
                    campground={campground as CampgroundModel}
                    nearestCity={thisNearestCity}
                    ref={(e) => {
                      if (e) {
                        cardElement.current[index] = e;
                      }
                    }}
                    position={index + 1}
                  />
                ) : (
                  <_CampgroundCard
                    className={styles.card}
                    hasBackground={hasBackground}
                    campground={campground}
                    ref={(e) => {
                      if (e) {
                        cardElement.current[index] = e;
                      }
                    }}
                    position={index + 1}
                  />
                )}
              </li>
            );
          })}
        {articles &&
          articles?.length > 0 &&
          articles.map((article, index) => (
            <li key={article.id} className={styles.listItem}>
              <_ArticleCard
                className={styles.card}
                article={article}
                ref={(e) => {
                  if (e) {
                    cardElement.current[index] = e;
                  }
                }}
              />
            </li>
          ))}
        {!!place &&
          filters &&
          filters?.length > 0 &&
          filters.map((filter, index) => (
            <li key={filter.value} className={styles.listItem}>
              <_FilterCard
                className={styles.card}
                filter={filter}
                place={place}
                ref={(e) => {
                  if (e) {
                    cardElement.current[index] = e;
                  }
                }}
              />
            </li>
          ))}
        {!!more && !articles && (
          <li className={styles.listItem}>
            <div
              className={styles.more}
              ref={(e) => {
                if (e) {
                  cardElement.current[campgrounds ? campgrounds.length : 0] = e;
                }
              }}
            >
              <AppButtonLink
                label="Find More"
                path={more}
                dataEvent="click_carousel_find_more"
              />
            </div>
          </li>
        )}
      </div>
    </div>
  );
};

export default AppCardCarousel;
